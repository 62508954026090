import React, { useRef, useEffect } from 'react';
import styled, {css, keyframes} from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import SEO from '../components/seo';

import TransContainer from '../components/TransContainer';
import Layout from '../components/layout';
import ThreeDCard from '../components/ThreeDCard';
import MainText from '../components/shared/MainText';

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
  bottom: 0;
`;

const colorChange = keyframes`
  from {
    color: #000000;
  }
  to {
    color: #ffffff;
  }
`;

const Title = styled.h2`
  position: absolute;
  color: #000;
  animation: ${({ whiteTitle }) =>
    whiteTitle
      ? css`
          ${colorChange} 0.5s ease-in-out forwards
        `
      : 'none'};
  font-size: 14.5vw;
  line-height: 1;
  margin-bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 22px 15px 0;

  @media (min-width: 800px) {
    color: #000;
    padding: 22px 20px 0;
    font-size: 100px;
    position: relative;
    z-index: 1;
    animation: none;
  }
`;

const CardWrapper = styled.div`
  position: relative;
  top: 0;
  z-index: -1;
  width: 100vw;
  text-align: center;

  @media (min-width: 800px) {
    position: static;
    height: initial;
    width: initial;
    margin-top: -7vh;
  }
  & > div {
    position: relative;
    display: inline-block;
    bottom: 0;
    right: 0;
  }
`;

const Video = styled.video`
  width: 100vw;
  height: auto;

  @media (min-width: 800px) {
    height: 80vh;
    width: auto;
  }
`;

const Photo = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  background-position: center;

  @media (min-width: 800px) {
    height: calc(100vh - 100px);
    width: 85vw;
  }
`;

const StyledMainText = styled(MainText)`
  margin: 0;
  margin-top: 60px;

  @media (min-width: 800px) {
    margin: 120px 0 60px;
  }
`;

const SmallMainText = styled(MainText)`
  margin: 80px 0;

  @media (min-width: 800px) {
    font-size: 24px;
    line-height: 1.2;
    margin: 60px 0;
    max-width: 550px;
  }

  p {
    margin-bottom: 20px;
  }
`;

const BigLinksWrapper = styled.div`
  padding: 0 15px;
  margin: 120px 0 140px;
  text-align: center;

  @media (min-width: 800px) {
    margin: 120px 0 150px;
    padding: 0 20px;
  }
`;

const BigLinkBack = styled.div`
  font-family: kormelink;
  letter-spacing: 0.1px;
  font-size: 21px;
  line-height: 26px;
  margin-bottom: 10px;

  @media (min-width: 800px) {
    font-size: 38px;
    line-height: 47px;
    margin-bottom: 20px;

    &:hover {
      text-decoration: underline;
    }
  }

  a {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const About = props => {

  const videoEl = useRef(null);

  useEffect(() => {
    if (videoEl && videoEl.current && typeof window !== 'undefined' && window.innerWidth < 800 ) {
      videoEl.current.muted = true;
      videoEl.current.play();
    }
  }, []);

  const renderName = name => {
    const splitName = name.split('');
    let newName = [];

    splitName.forEach((item, i) => {
      newName.push(<span key={item + i}>{item}</span>);
    });

    return newName;
  };

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 799px)' });

  return (
    <StaticQuery
      query={graphql`
        query {
          sako {
            aboutPages {
              title
              videoDesktop {
                url
              }
              image {
                url
              }
              videoMobile {
                url
              }
              mobileImage {
                url
              }
              whiteTitle
              text {
                html
              }
              credits {
                html
              }
            }
          }
        }
      `}
      render={data => {
        const content = data.sako.aboutPages[0];

        return (
          <Layout transitionStatus={props.transitionStatus}>
            <SEO title='Paweł Sakowicz | About' keywords={[`Paweł Sakowicz`, `choreography`, `dance`]} />
            <Wrapper>
              <Title whiteTitle={content.whiteTitle}>{renderName(content.title.toUpperCase())}</Title>
              <TransContainer>
                <CardWrapper>
                  <ThreeDCard>
                    {typeof window !== 'undefined' && 
                      (!isTabletOrMobile && content.videoDesktop) || (isTabletOrMobile && content.videoMobile) ? (
                        <Video ref={videoEl} autoPlay loop muted={true} defaultMuted playsInline poster={isTabletOrMobile ? content.mobileImage.url : content.image.url}>
                          <source src={typeof window !== 'undefined' && window.innerWidth < 800  ? content.videoMobile.url : content.videoDesktop.url} type='video/mp4' />
                        </Video>
                      ) : (
                        <Photo imageUrl={typeof window !== 'undefined' && window.innerWidth < 800  ? content.mobileImage.url : content.image.url} />
                      ) 
                    }   
                  </ThreeDCard>
                </CardWrapper>

                <StyledMainText dangerouslySetInnerHTML={{ __html: content.text.html }}></StyledMainText>

                <SmallMainText dangerouslySetInnerHTML={{ __html: content.credits.html }}></SmallMainText>
              </TransContainer>
            </Wrapper>

            <BigLinksWrapper>
              <BigLinkBack>
                <Link to='/'>&#x021A9; Back</Link>
              </BigLinkBack>
            </BigLinksWrapper>
          </Layout>
        );
      }}
    />
  );
};

export default About;

import styled from 'styled-components';

const MainText = styled.div`
  font-family: kormelink;
  letter-spacing: 0.1px;
  font-size: 21px;
  line-height: 26px;
  text-align: left;
  padding: 0 15px;
  margin: 120px 0;

  @media (min-width: 800px) {
    font-size: 38px;
    line-height: 47px;
    padding: 0 20px;
  }

  a {
    text-decoration: underline;

    @media (hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }
  }

  p {
    margin-bottom: 26px;

    @media (min-width: 800px) {
      margin-bottom: 50px;
    }
  }
`;

export default MainText;
